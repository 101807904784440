import { Fade } from 'react-reveal';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Perfil } from '../components/Perfil';
import { Service } from '../components/Service';
import { Testimonial } from '../components/Testimonial';

export const Main = () => {
  return (
    <div>
      <Header />
      <Service />
      <Fade left>
        <Testimonial />
        <Perfil />
      </Fade>
      <Footer />
    </div>
  );
};
