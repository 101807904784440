import especialistas from '../../assets/img/fotografias/especialistas.jpg';
import especialistas2 from '../../assets/img/fotografias/especialistas2.jpg';

export const Doctores = () => {
  return (
    <>
      <div id="team" className="team-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title-box">
                <h2>Nuestros Doctores</h2>
                <p>Ellos son quienes nos ayudan a mejorar</p>
              </div>
            </div>
          </div>

          <div className="row">
            {/*  */}
            <div className="col-md-4 col-sm-6">
              <div className="our-team">
                <div className="pic">
                  <img src={especialistas} alt="" />
                </div>
                <div className="team-content">
                  <h3 className="title">Hosman Fernandez</h3>
                  <span className="post">Especialista en Fisioterapia</span>
                  <ul className="social">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-google-plus" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i
                          className="fa fa-youtube-play"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  */}
            {/*  */}
            <div className="col-md-4 col-sm-6">
              <div className="our-team">
                <div className="pic">
                  <img src={especialistas} alt="" />
                </div>
                <div className="team-content">
                  <h3 className="title">Hosman Fernandez</h3>
                  <span className="post">Especialista en Fisioterapia</span>
                  <ul className="social">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-google-plus" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i
                          className="fa fa-youtube-play"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  */}
            {/*  */}
            <div className="col-md-4 col-sm-6">
              <div className="our-team">
                <div className="pic">
                  <img src={especialistas} alt="" />
                </div>
                <div className="team-content">
                  <h3 className="title">Hosman Fernandez</h3>
                  <span className="post">Especialista en Fisioterapia</span>
                  <ul className="social">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-google-plus" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i
                          className="fa fa-youtube-play"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
};
