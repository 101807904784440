import { useState } from 'react';
import { Card } from '../components/Card';
import Carousel from 'react-elastic-carousel';
import revision from '../assets/img/fotografias/revision.jpg';
import deportiva from '../assets/img/img-card/Terapias/terapia-deportiva.jpg';
import quemaduras from '../assets/img/img-card/Terapias/terapia-quemaduras.jpg';
import electroterapia from '../assets/img/img-card/Terapias/electroterapia.jpg';
import golfista from '../assets/img/img-card/Lesiones/codo-golfista.jpg';
import tenista from '../assets/img/img-card/Lesiones/codo-tenista.jpg';
import espalda from '../assets/img/img-card/Lesiones/dolor-espalda.jpg';
import hernia from '../assets/img/img-card/Lesiones/dolor-espalda-hernia.jpg';
import esguince from '../assets/img/img-card/Lesiones/esguince-tobillo.jpg';
import mano from '../assets/img/img-card/Lesiones/fractura-mano.jpg';
import tobillo from '../assets/img/img-card/Lesiones/fractura-tobillo.jpg';
import hombro from '../assets/img/img-card/Lesiones/hombro-congelado.jpg';
import ligamento from '../assets/img/img-card/Lesiones/ligamento-cruzado-anterior.jpg';
import ciatico from '../assets/img/img-card/Lesiones/nervio-ciatico.webp';
import cadera from '../assets/img/img-card/Lesiones/reemplazo-cadera.jpg';
import rodilla from '../assets/img/img-card/Lesiones/reemplazo-rodilla.jpg';
import carpiano from '../assets/img/img-card/Lesiones/sindrome-carpiano.jpg';
import tendinitis from '../assets/img/img-card/Lesiones/tendinitis.jpg';
import useScreenSize from '../hooks/useScreenSize';

export const Service = () => {
  const { width, height } = useScreenSize();
  const [service, setService] = useState('rehabilitación');

  return (
    <>
      <div className="service">
        <p>Servicios</p>
        <div className="service-links">
          {service == 'rehabilitación' ? (
            <a
              href="#Servicios"
              className="active-links"
              onClick={() => setService('rehabilitación')}
            >
              rehabilitación
            </a>
          ) : (
            <a
              href="#Servicios"
              className="no-active-links"
              onClick={() => setService('rehabilitación')}
            >
              rehabilitación
            </a>
          )}

          {service == 'terapia' ? (
            <a
              href="#Servicios"
              className="active-links"
              onClick={() => setService('terapia')}
            >
              Terapia
            </a>
          ) : (
            <a
              href="#Servicios"
              className="no-active-links"
              onClick={() => setService('terapia')}
            >
              Terapia
            </a>
          )}

          {service == 'lesiones' ? (
            <a
              href="#Servicios"
              className="active-links"
              onClick={() => setService('lesiones')}
            >
              Lesiones
            </a>
          ) : (
            <a
              href="#Servicios"
              className="no-active-links"
              onClick={() => setService('lesiones')}
            >
              Lesiones
            </a>
          )}
        </div>
      </div>
      <div className="container-card">
        {service === 'rehabilitación' ? (
          <>
            <Carousel
              itemsToShow={width <= 480 ? 1 : 4}
              enableAutoPlay
              autoPlaySpeed={3500}
            >
              <Card
                id="/prequirurgica"
                props="rehabilitación"
                condicion="Pre-Quirurgica"
                img="https://fisiobcn.eu/wp-content/uploads/2019/10/fisioterapia-prequirurgica.jpg"
              />
              <Card
                id="/postquirurgica"
                props="rehabilitación"
                img={revision}
                condicion="Post-Quirurgica"
              />
              <Card
                id="/prequirurgica"
                props="rehabilitación"
                condicion="Pre-Quirurgica"
                img="https://fisiobcn.eu/wp-content/uploads/2019/10/fisioterapia-prequirurgica.jpg"
              />
              <Card
                id="/postquirurgica"
                props="rehabilitación"
                img={revision}
                condicion="Post-Quirurgica"
              />
              <Card
                id="/prequirurgica"
                props="rehabilitación"
                condicion="Pre-Quirurgica"
                img="https://fisiobcn.eu/wp-content/uploads/2019/10/fisioterapia-prequirurgica.jpg"
              />
              <Card
                id="/postquirurgica"
                props="rehabilitación"
                img={revision}
                condicion="Post-Quirurgica"
              />

              <Card
                id="/prequirurgica"
                props="rehabilitación"
                condicion="Pre-Quirurgica"
                img="https://fisiobcn.eu/wp-content/uploads/2019/10/fisioterapia-prequirurgica.jpg"
              />
              <Card
                id="/postquirurgica"
                props="rehabilitación"
                img={revision}
                condicion="Post-Quirurgica"
              />
              <Card
                id="/prequirurgica"
                props="rehabilitación"
                condicion="Pre-Quirurgica"
                img="https://fisiobcn.eu/wp-content/uploads/2019/10/fisioterapia-prequirurgica.jpg"
              />
              <Card
                id="/postquirurgica"
                props="rehabilitación"
                img={revision}
                condicion="Post-Quirurgica"
              />
              <Card
                id="/prequirurgica"
                props="rehabilitación"
                condicion="Pre-Quirurgica"
                img="https://fisiobcn.eu/wp-content/uploads/2019/10/fisioterapia-prequirurgica.jpg"
              />
              <Card
                id="/postquirurgica"
                props="rehabilitación"
                img={revision}
                condicion="Post-Quirurgica"
              />

              <Card
                id="/prequirurgica"
                props="rehabilitación"
                condicion="Pre-Quirurgica"
                img="https://fisiobcn.eu/wp-content/uploads/2019/10/fisioterapia-prequirurgica.jpg"
              />
              <Card
                id="/postquirurgica"
                props="rehabilitación"
                img={revision}
                condicion="Post-Quirurgica"
              />
              <Card
                id="/prequirurgica"
                props="rehabilitación"
                condicion="Pre-Quirurgica"
                img="https://fisiobcn.eu/wp-content/uploads/2019/10/fisioterapia-prequirurgica.jpg"
              />
              <Card
                id="/postquirurgica"
                props="rehabilitación"
                img={revision}
                condicion="Post-Quirurgica"
              />
            </Carousel>
          </>
        ) : null}
        {service === 'terapia' ? (
          <>
            <Carousel
              itemsToShow={width <= 480 ? 1 : 4}
              enableAutoPlay
              autoPlaySpeed={3500}
            >
              <Card
                condicion="Terapia Deportiva"
                id="/terapiadeportiva"
                props="terapia"
                img={deportiva}
              />
              <Card
                condicion="Terapia por Quemaduras"
                id="/terapiaquemaduras"
                props="terapia"
                img={quemaduras}
              />
              <Card
                condicion="Electroterapia"
                id="/electroterapia"
                props="terapia"
                img={electroterapia}
              />
              <Card
                condicion="Terapia Deportiva"
                id="/terapiadeportiva"
                props="terapia"
                img={deportiva}
              />
              <Card
                condicion="Terapia por Quemaduras"
                id="/terapiaquemaduras"
                props="terapia"
                img={quemaduras}
              />
              <Card
                condicion="Electroterapia"
                id="/electroterapia"
                props="terapia"
                img={electroterapia}
              />

              <Card
                condicion="Terapia Deportiva"
                id="/terapiadeportiva"
                props="terapia"
                img={deportiva}
              />
              <Card
                condicion="Terapia por Quemaduras"
                id="/terapiaquemaduras"
                props="terapia"
                img={quemaduras}
              />
              <Card
                condicion="Electroterapia"
                id="/electroterapia"
                props="terapia"
                img={electroterapia}
              />
              <Card
                condicion="Terapia Deportiva"
                id="/terapiadeportiva"
                props="terapia"
                img={deportiva}
              />
              <Card
                condicion="Terapia por Quemaduras"
                id="/terapiaquemaduras"
                props="terapia"
                img={quemaduras}
              />
              <Card
                condicion="Electroterapia"
                id="/electroterapia"
                props="terapia"
                img={electroterapia}
              />
            </Carousel>
          </>
        ) : null}
        {service === 'lesiones' ? (
          <>
            <Carousel
              itemsToShow={width <= 480 ? 1 : 4}
              enableAutoPlay
              autoPlaySpeed={3500}
            >
              <Card
                categoria="Hombro"
                props="lesiones"
                condicion="Tendinitis del Bicep"
                id="/tendinitis"
                img={tendinitis}
              />
              <Card
                categoria="Hombro"
                props="lesiones"
                condicion="Hombro Congelado"
                id="/congelado"
                img={hombro}
              />
              <Card
                categoria="Espalda"
                props="lesiones"
                condicion="Dolor de Espalda"
                id="/espalda"
                img={espalda}
              />
              <Card
                categoria="Espalda"
                props="lesiones"
                condicion="Dolor de Espalda por Hernia"
                id="/espaldahernia"
                img={hernia}
              />
              <Card
                categoria="Cadera"
                props="lesiones"
                condicion="Reemplazo de Cadera"
                id="/caderaprotesis"
                img={cadera}
              />
              <Card
                categoria="Cadera"
                props="lesiones"
                condicion="Nervio Ciatica"
                id="/ciatica"
                img={ciatico}
              />
              <Card
                categoria="Rodilla"
                props="lesiones"
                condicion="Ligamento Cruzado Interior"
                id="/ligamentorodilla"
                img={ligamento}
              />
              <Card
                categoria="Rodilla"
                props="lesiones"
                condicion="Reemplazo de Rodilla"
                id="/reemplazorodilla"
                img={rodilla}
              />
              <Card
                categoria="Mano"
                props="lesiones"
                condicion="Fractura de Mano"
                id="/mano"
                img={mano}
              />
              <Card
                categoria="Mano"
                props="lesiones"
                condicion="Sindrome de tunel carpiano"
                id="/carpiano"
                img={carpiano}
              />
              <Card
                categoria="Codo"
                props="lesiones"
                condicion="Codo de Tenista"
                id="/codo"
                img={tenista}
              />
              <Card
                categoria="Codo"
                props="lesiones"
                condicion="Codo de Golfista"
                id="/codogolfista"
                img={golfista}
              />
              <Card
                categoria="Tobillo"
                props="lesiones"
                condicion="Esguince de Tobillo"
                id="/tobillo"
                img={esguince}
              />

              <Card
                categoria=""
                props="lesiones"
                condicion="Fractura de Tobillo"
                id="/fracturatobillo"
                img={tobillo}
              />
            </Carousel>
          </>
        ) : null}
      </div>
    </>
  );
};
