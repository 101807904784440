import React from 'react';
import cerrar from '../assets/img/cerrar.png';
import '../assets/styles/menu-desplegable.css';
import { Link } from 'react-router-dom';

export const Menu = () => {
  return (
    <>
      <div className="menu-desplegable">
        <div>
          <a href="/">
            <img src={cerrar} />
          </a>
        </div>
        <Link to="/cefise" className="text-links">
          ¿Qué es Cefise?
        </Link>
        <Link to="/fisioterapia" className="text-links">
          Fisioterapia
        </Link>
        <Link to="/trayectoria" className="text-links">
          Trayectoria
        </Link>
        <Link to="/contactanos" className="text-links">
          Contáctenos
        </Link>
      </div>
    </>
  );
};
