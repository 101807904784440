import { Footer } from '../Footer';
import { Header } from '../Header';
import { Doctores } from './Doctores';
import { Galeria } from './Galeria';

export const PreQuirurgica = () => {
  return (
    <>
      <Header />

      <div>
        <html lang="en">
          <head>
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />

            <meta
              name="viewport"
              content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />

            <meta name="keywords" content="" />
            <meta name="description" content="" />
            <meta name="author" content="" />

            <link
              rel="shortcut icon"
              href="images/favicon.ico"
              type="image/x-icon"
            />
            <link rel="apple-touch-icon" href="images/apple-touch-icon.png" />

            <link rel="stylesheet" href="css/bootstrap.min.css" />
            <link rel="stylesheet" href="css/pogo-slider.min.css" />
            <link rel="stylesheet" href="css/style.css" />
            <link rel="stylesheet" href="css/responsive.css" />
            <link rel="stylesheet" href="css/custom.css" />

            <script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js"></script>
            <script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js"></script>
          </head>
          <body
            id="home"
            data-spy="scroll"
            data-target="#navbar-wd"
            data-offset="98"
          >
            <div id="about" className="about-box">
              <div className="about-a1">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      {/* <div className="title-box">
                        <h2>About Us</h2>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="row align-items-center about-main-info">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <h2>Fisioterapia Pre-Quirurgica</h2>
                          <p>
                            Si se siente un poco preocupado por la forma en que
                            se recuperará después de la cirugía, no está solo.
                            Todo el mundo ha oído historias de lo difícil que
                            puede ser volver a ponerse de pie después de una
                            operación. Incluso puede haber pasado por una
                            cirugía antes, por lo que tiene experiencia de
                            primera mano. Afortunadamente, hay un enfoque
                            relativamente nuevo de la fisioterapia y la cirugía
                            que está ayudando a muchas personas a recuperarse
                            más rápido y más plenamente.la rehabilitación
                            prequirúrgica puede hacer la vida después de la
                            cirugía más fácil.
                          </p>
                          <p>
                            La rehabilitación prequirúrgica, a menudo llamada
                            pre-hab, es un concepto simple. En lugar de esperar
                            hasta después de haber pasado por la cirugía para
                            hacer la rehabilitación, ¿por qué no hacer algo
                            antes también… Puede no parecer terriblemente
                            innovador, pero la pre-hab ha ido ganando mucha
                            popularidad entre los cirujanos, los pacientes y los
                            fisioterapeutas de – porque ofrece resultados
                            serios.
                          </p>
                          {/* <a href="#" className="new-btn-d br-2">
                            Read More
                          </a> */}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="about-m">
                            <ul id="banner" className="banner-prequirurgica">
                              <li>
                                <img src="https://blog.therapycord.mx/wp-content/uploads/2021/03/rehabilitación-fisioterapia-recuperacion.jpg" />
                              </li>
                              <li>
                                <img
                                  src="https://blog.therapycord.mx/wp-content/uploads/2021/03/rehabilitación-fisioterapia-recuperacion.jpg"
                                  alt=""
                                />
                              </li>
                              <li>
                                <img
                                  src="https://blog.therapycord.mx/wp-content/uploads/2021/03/rehabilitación-fisioterapia-recuperacion.jpg"
                                  alt=""
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Galeria />

            <Doctores />

            <a href="#" id="scroll-to-top" className="new-btn-d br-2">
              <i className="fa fa-angle-up"></i>
            </a>

            <script src="js/jquery.min.js"></script>
            <script src="js/popper.min.js"></script>
            <script src="js/bootstrap.min.js"></script>
            <script src="js/jquery.magnific-popup.min.js"></script>
            <script src="js/jquery.pogo-slider.min.js"></script>
            <script src="js/slider-index.js"></script>
            <script src="js/smoothscroll.js"></script>
            <script src="js/TweenMax.min.js"></script>
            <script src="js/main.js"></script>
            <script src="js/owl.carousel.min.js"></script>
            <script src="js/form-validator.min.js"></script>
            <script src="js/contact-form-script.js"></script>
            <script src="js/isotope.min.js"></script>
            <script src="js/images-loded.min.js"></script>
            <script src="js/custom.js"></script>
          </body>
        </html>
      </div>

      <Footer />
    </>
  );
};
