import '../assets/styles/trayectoria/trayectoria.css';
import gimnasio from '../assets/img/fotografias/gimnasio.jpg';
import gimnasio2 from '../assets/img/fotografias/gimnasio2.jpg';
import recepcion from '../assets/img/fotografias/recepcion.jpg';
import patio1 from '../assets/img/patio1.jpg';
import patio2 from '../assets/img/patio2.jpg';
import patio3 from '../assets/img/patio3.jpg';
import patio4 from '../assets/img/patio4.jpg';
import patio5 from '../assets/img/patio5.jpg';
import patio7 from '../assets/img/patio7.jpg';
import cefise from '../assets/img/cefise-quienes-somos.png';

export const Trayectoria = () => {
  return (
    <>
      <div className="container-trayectoria">
        <h1>Trayectoria</h1>
        <div className="linea-trayectoria"></div>
        <br />
        <hr />
        <div className="bar-trayectoria">
          <img src={cefise} />
        </div>
        <div className="trayectoria-texto">
          <div className="trayectoria-parrafo">
            <h2>
              El nacimiento de CEFISE es fruto de una vocación de servicio y
              contribución.
            </h2>
            <div className="trayectoria-images">
              <img src={gimnasio} />
              <img src={gimnasio2} />
              <img src={recepcion} />
            </div>
            v
            <div>
              {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non
              facilis nesciunt incidunt sapiente similique voluptas. Odio
              dolorum quis debitis, laboriosam, totam, molestias impedit
              excepturi voluptatem cupiditate magni ipsam illum! Perferendis!
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non
              facilis nesciunt incidunt sapiente similique voluptas. Odio
              dolorum quis debitis, laboriosam, totam, molestias impedit
              excepturi voluptatem cupiditate magni ipsam illum! Perferendis! */}
            </div>
          </div>
          <div className="trayectoria-parrafo">
            <h2>Nuestra historia es la historia de nuestros clientes</h2>
            <div className="trayectoria-images">
              <img src={patio1} />
              <img src={patio2} />
              <img src={patio3} />
            </div>
            <div>
              {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non
              facilis nesciunt incidunt sapiente similique voluptas. Odio
              dolorum quis debitis, laboriosam, totam, molestias impedit
              excepturi voluptatem cupiditate magni ipsam illum! Perferendis!
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non
              facilis nesciunt incidunt sapiente similique voluptas. Odio
              dolorum quis debitis, laboriosam, totam, molestias impedit
              excepturi voluptatem cupiditate magni ipsam illum! Perferendis! */}
            </div>
          </div>
          <hr />
          <div className="satisfaccion">
            <h2>
              La satisfacción de nuestros clientes es nuestra tarjeta de visita.
            </h2>
            <div className="trayectoria-images">
              <img src={patio4} />
              <img src={patio5} />
              <img src={patio7} />
            </div>
            <div>
              {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero ad
              consequuntur at vitae molestias sed? Pariatur provident maxime
              modi praesentium nostrum ipsa harum quos quis! Nemo vero assumenda
              error ipsum! */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
