import '../assets/styles/fisioterapia/fisioterapia.css';
import fisioterapia from '../assets/img/fisioterapia.jpg';
import fisioterapeuta from '../assets/img/fisioterapeuta.jpg';
import marco from '../assets/img/marco de competencias.jpg';

import { useActive } from '../hooks/useActive';
import { Fade } from 'react-reveal';

export const Fisioterapia = () => {
  const { active, state, tercer, cuarto, quinto, sexto, septimo } = useActive();

  return (
    <>
      <div className="container-fisio">
        <div className="fisioterapia">
          <Fade left duration={1000}>
            <div className="container-one">
              <img src={fisioterapia} />
              <div>
                <h3>¿Que es la Fisioterapia?</h3>
                <p>
                  Según la Confederación Mundial de la Fisioterapia (W.C.P.T.)
                  define la Fisioterapia como: “El conjunto de métodos,
                  actuaciones y técnicas que, mediante la aplicación de medios
                  físicos, curan, previenen, recuperan y adaptan a personas
                  afectadas de disfunciones somáticas o a las que se desea
                  mantener en un nivel adecuado de salud”. Los objetivos de la
                  Fisioterapia son promover, prevenir, mantener, restablecer y
                  aumentar el nivel de salud y calidad de vida de la persona.
                </p>
              </div>
            </div>
          </Fade>

          <div className="linea-fisio"></div>

          <Fade right duration={1000}>
            <div className="container-two">
              <div>
                <h3>Funciones del Fisioterapeuta</h3>
                <p>
                  El trabajo principal del fisioterapeuta radica en ofrecer un
                  tratamiento especifico enfocado especialmente en la
                  rehabilitación o recuperación de lesiones a sus pacientes,
                  proporcionándoles soluciones confiables a sus problemas
                  físicos.
                </p>
              </div>
              <img src={fisioterapeuta} />
            </div>
          </Fade>

          <div className="linea-fisio"></div>

          <Fade bottom duration={1500}>
            <div className="container-three">
              <h3>Marco de competencias del Fisioterapeuta</h3>
              <img src={marco} />
              <p>
                <ul>
                  <li className={active}>
                    Valorar el estado funcional del paciente, considerando los
                    aspectos físicos, psicológicos y sociales.{' '}
                  </li>
                  <li className={state}>
                    Aplicar los procedimientos adecuados de valoración en
                    Fisioterapia.{' '}
                  </li>
                  <li className={tercer}>
                    Emitir el Diagnostico de Fisioterapia de acuerdo con las
                    normas reconocidas internacionalmente y con instrumentos
                    fiables de validación.{' '}
                  </li>
                  <li className={cuarto}>
                    Planificar los procedimientos y/o protocolos de atención al
                    paciente.{' '}
                  </li>
                  <li className={quinto}>
                    Evaluar la evolución de los resultados y realizar los
                    informes de Fisioterapia.{' '}
                  </li>
                  <li className={sexto}>
                    Establecer una relación y comunicación efectiva con todo el
                    equipo multidisciplinario.
                  </li>
                  <li className={septimo}>
                    Incorporar a la cultura profesional los principios éticos y
                    legales de la profesión.
                  </li>
                </ul>
              </p>
            </div>
          </Fade>

          <div className="linea-fisio"></div>
        </div>
      </div>
    </>
  );
};
