import { Fade } from 'react-reveal';
import { Footer, Header, Trayectoria } from '../components/Index';

export const TrayectoriaPage = () => {
  return (
    <>
      <Header />
      <Fade bottom>
        <Trayectoria />
        <Footer />
      </Fade>
    </>
  );
};
