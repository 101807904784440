import { Fade } from 'react-reveal';
import { Contactanos } from '../components/Contactanos';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';

export const ContactanosPage = () => {
  return (
    <>
      <div>
        <Header />
        <Fade top>
          <Contactanos />
        </Fade>
        <Footer />
      </div>
    </>
  );
};
