import { Link } from 'react-router-dom';
import '../assets/styles/contactanos/contactanos.css';
import icolocation from '../assets/img/ico-location.svg';
import icophone from '../assets/img/ico-phone.png';

export const Contactanos = () => {
  const url =
    'https://www.google.com/maps/place/Cefise,+Bo.+Guamilito,+9+ave,+%2371+9+Calle+A+NO,+San+Pedro+Sula+21102/@15.5139182,-88.0282828,15z/data=!4m6!3m5!1s0x8f665b3939664633:0x8edf77555f049db!8m2!3d15.5139182!4d-88.0282828!16s%2Fg%2F11g6_4hrtv';

  return (
    <>
      <div className="container-contactanos">
        <div className="contact-form">
          <div className="contactanos-texto">
            <div className="contactanos-parrafo">
              <h1>Contactenos</h1>
              <p>
                Por aqui puedes contactarnos y escribirnos y con gusto
                <br />
                responderemos a cualquier duda o incognita que tengas,
                <br />
                no dudes y mandanos un mensaje
                <br />
              </p>
            </div>
            <div className="container---two">
              <div className="contactanos-telefono">
                <img src={icophone} id="ico-transform" />
                <h2>Escribe o llamanos</h2>
                <span>+(504) 9519-3600</span>
                <span>info@cefisehn.com</span>
              </div>
              <div className="contactanos-ubicacion">
                <img src={icolocation} id="ico-transform" />
                <h2>Ubicacion</h2>
                <span>
                  Bo. Guamilito. 9 Calle. 9 Ave.{' '}
                  <a href="https://www.google.com/maps/place/Col+Zer%C3%B3n,+San+Pedro+Sula+21102/@15.5169469,-88.0372881,15z/data=!4m6!3m5!1s0x8f665b136a369fbb:0xf899cd8d092b33fe!8m2!3d15.5167893!4d-88.0375314!16s%2Fg%2F1tzgkq0y"></a>
                </span>
              </div>
            </div>
          </div>
          <div className="container---one">
            <form className="contactanos-form">
              <input type="text" name="name" placeholder="Nombre" />
              <input type="text" name="last_name" placeholder="Apellido" />
              <input type="text" name="phone" placeholder="Telefono." />
              <input type="text" name="mail" placeholder="Correo" />
              <input type="textarea" name="mensaje" />
              <button className="contactanos-button" type="submit">
                Enviar Mensaje
              </button>
            </form>
          </div>
          <div className="contactenos-mensaje"></div>
        </div>
      </div>
    </>
  );
};
