import image01 from '../../assets/img/fisioterapia postquirurgica/image-01.jpg';
import image02 from '../../assets/img/fisioterapia postquirurgica/image-02.jpg';
import image03 from '../../assets/img/fisioterapia postquirurgica/image-03.jpg';
import image04 from '../../assets/img/fisioterapia postquirurgica/image-04.jpg';
import image05 from '../../assets/img/fisioterapia postquirurgica/image-05.jpg';
import image06 from '../../assets/img/fisioterapia postquirurgica/image-06.jpg';
import image07 from '../../assets/img/fisioterapia postquirurgica/image-07.jpg';
import image08 from '../../assets/img/fisioterapia postquirurgica/image-08.jpg';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { Doctores } from './Doctores';
import { Galeria } from './Galeria';

export const Carpiano = () => {
  return (
    <>
      <Header />
      <div>
        <html lang="en">
          <head>
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />

            <meta
              name="viewport"
              content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />

            <meta name="keywords" content="" />
            <meta name="description" content="" />
            <meta name="author" content="" />

            <link
              rel="shortcut icon"
              href="images/favicon.ico"
              type="image/x-icon"
            />
            <link rel="apple-touch-icon" href="images/apple-touch-icon.png" />

            <link rel="stylesheet" href="css/bootstrap.min.css" />
            <link rel="stylesheet" href="css/pogo-slider.min.css" />
            <link rel="stylesheet" href="css/style.css" />
            <link rel="stylesheet" href="css/responsive.css" />
            <link rel="stylesheet" href="css/custom.css" />

            <script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js"></script>
            <script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js"></script>
          </head>
          <body
            id="home"
            data-spy="scroll"
            data-target="#navbar-wd"
            data-offset="98"
          >
            <h1 id="lesiones">Lesiones de Mano</h1>

            <div id="about" className="about-box">
              <div className="about-a1">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      {/* <div className="title-box">
                        <h2>About Us</h2>
                        <p>
                          Ver Imagen sit amet, consectetur adipiscing
                          elit.
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="row align-items-center about-main-info">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <h2>Sindrome de Tunel Carpiano</h2>

                          {/* <h2>Tratamiento de fisioterapia para la ciática</h2> */}
                          <p>
                            El síndrome del túnel carpiano es una enfermedad que
                            produce daño del nervio mediano. Si existe, por
                            cualquier causa, un aumento de la presión dentro de
                            ese túnel carpiano se puede producir una lesión en
                            dicho nervio.
                          </p>
                          <p>
                            La anatomía de la muñeca, las condiciones de salud
                            subyacentes y los patrones de uso de la mano pueden
                            ser causas del síndrome del túnel carpiano. Los
                            síntomas principales son el dolor en las manos y los
                            brazos, con hormigueo o entumecimiento. El
                            tratamiento puede incluir reposo, hielo, una férula
                            en la muñeca, inyecciones de cortisona y cirugía.
                          </p>
                          {/* <a href="#" className="new-btn-d br-2">
                            Read More
                          </a> */}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="about-m">
                            <ul id="banner" className="banner-carpiano"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Galeria />

            <Doctores />

            <a href="#" id="scroll-to-top" className="new-btn-d br-2">
              <i className="fa fa-angle-up"></i>
            </a>

            <script src="js/jquery.min.js"></script>
            <script src="js/popper.min.js"></script>
            <script src="js/bootstrap.min.js"></script>
            <script src="js/jquery.magnific-popup.min.js"></script>
            <script src="js/jquery.pogo-slider.min.js"></script>
            <script src="js/slider-index.js"></script>
            <script src="js/smoothscroll.js"></script>
            <script src="js/TweenMax.min.js"></script>
            <script src="js/main.js"></script>
            <script src="js/owl.carousel.min.js"></script>
            <script src="js/form-validator.min.js"></script>
            <script src="js/contact-form-script.js"></script>
            <script src="js/isotope.min.js"></script>
            <script src="js/images-loded.min.js"></script>
            <script src="js/custom.js"></script>
          </body>
        </html>
      </div>
      <Footer />
    </>
  );
};
