import { Footer } from '../Footer';
import { Header } from '../Header';
import { Doctores } from './Doctores';
import { Galeria } from './Galeria';

export const TerapiaDeportiva = () => {
  return (
    <>
      <Header />
      <div>
        <html lang="en">
          <head>
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />

            <meta
              name="viewport"
              content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />

            <meta name="keywords" content="" />
            <meta name="description" content="" />
            <meta name="author" content="" />

            <link
              rel="shortcut icon"
              href="images/favicon.ico"
              type="image/x-icon"
            />
            <link rel="apple-touch-icon" href="images/apple-touch-icon.png" />

            <link rel="stylesheet" href="css/bootstrap.min.css" />
            <link rel="stylesheet" href="css/pogo-slider.min.css" />
            <link rel="stylesheet" href="css/style.css" />
            <link rel="stylesheet" href="css/responsive.css" />
            <link rel="stylesheet" href="css/custom.css" />

            <script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js"></script>
            <script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js"></script>
          </head>
          <body
            id="home"
            data-spy="scroll"
            data-target="#navbar-wd"
            data-offset="98"
          >
            <div id="about" className="about-box">
              <div className="about-a1">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      {/* <div className="title-box">
                          <h2>About Us</h2>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </p>
                        </div> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="row align-items-center about-main-info">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <h2>Terapia Deportiva ¿En que consiste?</h2>
                          <p>
                            La fisioterapia deportiva es una rama de la
                            fisioterapia, que va dirigida a toda persona que
                            practique deporte habitualmente, tanto si es un
                            deporte de base, amateur, de salud, de élite o alto
                            rendimiento. La fisioterapia deportiva consiste en
                            hacer un trabajo preventivo y otro de recuperación
                            de lesiones. ¿En qué consiste el trabajo preventivo?
                            Consiste en mejorar y trabajar la condición muscular
                            del deportista, evitando las cargas y corrigiendo el
                            mal gesto deportivo. Trabajaremos la musculatura con
                            masaje deportivo para descargar o tonificar según se
                            encuentre en ese momento el deportista. No es igual
                            el tratamiento cuando el deportista va a salir hacer
                            la actividad, que cuando el deportista ha acabado la
                            actividad deportiva, las maniobras son completamente
                            diferentes, los efectos que buscamos también los
                            son, las dos terapias son necesarias nos ayuda a
                            optimizar la buena condición muscular y estructural
                            del deportista.
                          </p>
                          <h2>Rehabilitación de la lesión</h2>

                          <p>
                            Se hará una buena planificación de los
                            entrenamientos, ejercicios de estabilidad articular,
                            buen trabajo de propiocepción, estiramientos,
                            fortalecimientos. También marcamos unas pautas de
                            conducta al deportista, que debe de seguir para
                            facilitar su recuperación. Nuestro objetivo es
                            recuperar la lesión en menor tiempo posible con toda
                            su funcionalidad. Tenemos que readaptar al
                            deportista a los entrenamientos. No se puede empezar
                            de 0 a 100, la vuelta a la actividad se hará
                            progresiva, primero adaptaremos al deportista al
                            entrenamiento, después readaptaremos al esfuerzo y
                            por último el gesto deportivo. El atleta debe
                            respetar los tiempos de recuperación, debe formar
                            equipo con los terapeutas y colaborar en todo
                            momento
                          </p>
                          {/* <a href="#" className="new-btn-d br-2">
                              Read More
                            </a> */}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="about-m">
                            <ul id="banner" className="banner-deportiva">
                              <li>
                                <img src="https://blog.therapycord.mx/wp-content/uploads/2021/03/rehabilitación-fisioterapia-recuperacion.jpg" />
                              </li>
                              <li>
                                <img
                                  src="https://blog.therapycord.mx/wp-content/uploads/2021/03/rehabilitación-fisioterapia-recuperacion.jpg"
                                  alt=""
                                />
                              </li>
                              <li>
                                <img
                                  src="https://blog.therapycord.mx/wp-content/uploads/2021/03/rehabilitación-fisioterapia-recuperacion.jpg"
                                  alt=""
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Galeria />

            <Doctores />

            <a href="#" id="scroll-to-top" className="new-btn-d br-2">
              <i className="fa fa-angle-up"></i>
            </a>

            <script src="js/jquery.min.js"></script>
            <script src="js/popper.min.js"></script>
            <script src="js/bootstrap.min.js"></script>
            <script src="js/jquery.magnific-popup.min.js"></script>
            <script src="js/jquery.pogo-slider.min.js"></script>
            <script src="js/slider-index.js"></script>
            <script src="js/smoothscroll.js"></script>
            <script src="js/TweenMax.min.js"></script>
            <script src="js/main.js"></script>
            <script src="js/owl.carousel.min.js"></script>
            <script src="js/form-validator.min.js"></script>
            <script src="js/contact-form-script.js"></script>
            <script src="js/isotope.min.js"></script>
            <script src="js/images-loded.min.js"></script>
            <script src="js/custom.js"></script>
          </body>
        </html>
      </div>
      <Footer />
    </>
  );
};
