import React from 'react';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Perfil } from '../components/Perfil';
import { Cefise } from '../components/Cefise';

export const CefisePage = () => {
  return (
    <>
      <Header />
      <Cefise />
      {/* <Perfil /> */}
      <Footer />
    </>
  );
};
