import '../assets/assets-enfermedad/css/style.css';
import '../assets/assets-enfermedad/css/responsive.css';
import '../assets/assets-enfermedad/css/responsive.css';
import '../assets/assets-enfermedad/css/bootstrap.min.css';
import '../assets/assets-enfermedad/css/pogo-slider.min.css';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import {
  PreQuirurgica,
  Electroterapia,
  PostQuirurgica,
  TerapiaDeportiva,
  TerapiaQuemaduras,
  Tendinitis,
  Congelado,
  Espalda,
  EspaldaHernia,
  CaderaProtesis,
  Ciatica,
  LigamentoRodilla,
  ReemplazoRodilla,
  Carpiano,
  Codo,
  CodoGolfista,
  Tobillo,
  FracturaTobillo,
} from '../components/Enfermedades';
import { Mano } from '../components/Enfermedades/Mano';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

export const EnfermedadesPage = () => {
  return (
    <>
      {/* <PreQuirurgica /> */}
      {/* <PostQuirurgica /> */}
      {/* <TerapiaDeportiva /> */}
      {/* <TerapiaQuemaduras /> */}
      {/* <Electroterapia /> */}
      {/* <Tendinitis /> */}
      {/* <Congelado /> */}
      {/* <Espalda /> */}
      {/* <EspaldaHernia /> */}
      {/* <CaderaProtesis /> */}
      {/* <Ciatica /> */}
      {/* <LigamentoRodilla /> */}
      {/* <ReemplazoRodilla /> */}
      {/* <Mano /> */}
      {/* <Carpiano /> */}
      {/* <Codo /> */}
      {/* <CodoGolfista /> */}
      {/* <Tobillo /> */}
      {/* <FracturaTobillo /> */}
    </>
  );
};
