import Carousel from 'react-elastic-carousel';
import banner from '../assets/img/banner-cefise.jpg';
import panomarica from '../assets/img/panomarica.jpg';
import panoramica2 from '../assets/img/panomarica2.jpg';
import patio1 from '../assets/img/patio1.jpg';
import patio2 from '../assets/img/patio2.jpg';
import patio3 from '../assets/img/patio3.jpg';
import patio4 from '../assets/img/patio4.jpg';
import patio5 from '../assets/img/patio5.jpg';
import patio6 from '../assets/img/patio6.jpg';
import patio7 from '../assets/img/patio7.jpg';

export const Testimonial = () => {
  return (
    <>
      <div className="testimonial">
        <h1>¡Reserva con lo mejor de lo mejor!</h1>
        <Carousel itemsToShow={1} enableAutoPlay autoPlaySpeed={3500}>
          <div className="banner">
            <div>
              <img src={banner} />
              {/* <img src={banner} /> */}
            </div>
          </div>
          <div className="banner">
            <div>
              <img src={panomarica} />
              {/* <img src={banner} /> */}
            </div>
          </div>
          <div className="banner">
            <div>
              <img src={panoramica2} />
              {/* <img src={banner} /> */}
            </div>
          </div>
          <div className="banner">
            <div>
              <img src={patio1} />
              {/* <img src={banner} /> */}
            </div>
          </div>
          <div className="banner">
            <div>
              <img src={patio2} />
              {/* <img src={banner} /> */}
            </div>
          </div>
          <div className="banner">
            <div>
              <img src={patio3} />
              {/* <img src={banner} /> */}
            </div>
          </div>
          <div className="banner">
            <div>
              <img src={patio4} />
              {/* <img src={banner} /> */}
            </div>
          </div>
          <div className="banner">
            <div>
              <img src={patio5} />
              {/* <img src={banner} /> */}
            </div>
          </div>
          <div className="banner">
            <div>
              <img src={patio6} />
              {/* <img src={banner} /> */}
            </div>
          </div>
          <div className="banner">
            <div>
              <img src={patio7} />
              {/* <img src={banner} /> */}
            </div>
          </div>
        </Carousel>

        <div className="next"></div>
      </div>
    </>
  );
};
