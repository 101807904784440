import { Fade } from 'react-reveal';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { Doctores } from './Doctores';
import { Galeria } from './Galeria';

export const CaderaProtesis = () => {
  return (
    <>
      <Fade>
        <Header />
        <div>
          <html lang="en">
            <head>
              <meta charset="utf-8" />
              <meta http-equiv="X-UA-Compatible" content="IE=edge" />

              <meta
                name="viewport"
                content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
              />

              <meta name="keywords" content="" />
              <meta name="description" content="" />
              <meta name="author" content="" />

              <link
                rel="shortcut icon"
                href="images/favicon.ico"
                type="image/x-icon"
              />
              <link rel="apple-touch-icon" href="images/apple-touch-icon.png" />

              <link rel="stylesheet" href="css/bootstrap.min.css" />
              <link rel="stylesheet" href="css/pogo-slider.min.css" />
              <link rel="stylesheet" href="css/style.css" />
              <link rel="stylesheet" href="css/responsive.css" />
              <link rel="stylesheet" href="css/custom.css" />

              <script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js"></script>
              <script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js"></script>
            </head>
            <body
              id="home"
              data-spy="scroll"
              data-target="#navbar-wd"
              data-offset="98"
            >
              <h1 id="lesiones">Lesiones de Cadera</h1>

              <div id="about" className="about-box">
                <div className="about-a1">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        {/* <div className="title-box">
                        <h2>About Us</h2>
                        <p>
                          Ver Imagen sit amet, consectetur adipiscing
                          elit.
                        </p>
                      </div> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row align-items-center about-main-info">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <h2>
                              Tratamiento por Reemplazo de Cadera(Protesis)
                            </h2>
                            <p>
                              Es importante destacar que la intervención
                              fisioterapéutica en el tratamiento post-quirúrgico
                              de una prótesis de rodilla y de cadera, debe
                              suceder lo más precoz posible, con la finalidad de
                              afectar lo menos posible la movilidad y
                              funcionalidad de la zona, siempre y cuando se
                              tomen las medidas y precauciones pertinentes ante
                              la situación. Si has pasado recientemente por una
                              cirugía de reemplazo articular bien sea de rodilla
                              o cadera, no dudes en contactarnos, CEFISE cuenta
                              con profesionales fisioterapeutas, capacitados y
                              con una amplia experiencia en la rehabilitación de
                              variedad de procedimientos quirúrgicos, que te
                              ayudarán a recuperar las habilidades y funciones
                              necesarias para lograr retornar óptimamente a tus
                              actividades de la vida diaria, a tráves de
                              tratamientos realizados en nuestras instalaciones
                              de Fisioterapia
                            </p>
                            {/* <p>
                            Una hernia de disco, que puede ocurrir en cualquier
                            parte de la espina dorsal, ocurre con mayor
                            frecuencia en la región lumbar. Según la ubicación
                            de la hernia de disco, puede provocar dolor,
                            entumecimiento o debilidad en un brazo o una pierna.
                            Muchas personas no tienen síntomas de una hernia de
                            disco. En el caso de las personas que presentan
                            síntomas, estos tienden a mejorar con el tiempo. Por
                            lo general, la cirugía no es necesaria para aliviar
                            el problema.
                          </p> */}
                            {/* <h2>¿Qué es la tendinitis del bíceps?</h2> */}

                            {/* <a href="#" className="new-btn-d br-2">
                            Read More
                          </a> */}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="about-m">
                              <ul id="banner" className="banner-cadera"></ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Galeria />

              <Doctores />

              <a href="#" id="scroll-to-top" className="new-btn-d br-2">
                <i className="fa fa-angle-up"></i>
              </a>

              <script src="js/jquery.min.js"></script>
              <script src="js/popper.min.js"></script>
              <script src="js/bootstrap.min.js"></script>
              <script src="js/jquery.magnific-popup.min.js"></script>
              <script src="js/jquery.pogo-slider.min.js"></script>
              <script src="js/slider-index.js"></script>
              <script src="js/smoothscroll.js"></script>
              <script src="js/TweenMax.min.js"></script>
              <script src="js/main.js"></script>
              <script src="js/owl.carousel.min.js"></script>
              <script src="js/form-validator.min.js"></script>
              <script src="js/contact-form-script.js"></script>
              <script src="js/isotope.min.js"></script>
              <script src="js/images-loded.min.js"></script>
              <script src="js/custom.js"></script>
            </body>
          </html>
        </div>
        <Footer />
      </Fade>
    </>
  );
};
