import image01 from '../../assets/img/fisioterapia postquirurgica/image-01.jpg';
import image02 from '../../assets/img/fisioterapia postquirurgica/image-02.jpg';
import image03 from '../../assets/img/fisioterapia postquirurgica/image-03.jpg';
import image04 from '../../assets/img/fisioterapia postquirurgica/image-04.jpg';
import image05 from '../../assets/img/fisioterapia postquirurgica/image-05.jpg';
import image06 from '../../assets/img/fisioterapia postquirurgica/image-06.jpg';
import image07 from '../../assets/img/fisioterapia postquirurgica/image-07.jpg';

export const Galeria = () => {
  return (
    <>
      <div id="gallery" className="gallery-box">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="title-box">
                <h2>Galeria</h2>
                <p>Galeria de imagenes sobre la fisioterapia</p>
              </div>
            </div>
          </div>

          <div className="popup-gallery row clearfix">
            <div className="col-md-3 col-sm-6">
              <div className="box-gallery">
                <img id="gal-backImg" src={image01} alt="" />
                <div className="box-content">
                  <h3 className="title">Ver Imagen</h3>
                  <ul className="icon">
                    <li>
                      <a href="">
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box-gallery">
                <img id="gal-backImg" src={image02} alt="" />
                <div className="box-content">
                  <h3 className="title">Ver Imagen</h3>
                  <ul className="icon">
                    <li>
                      <a href="">
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box-gallery">
                <img id="gal-backImg" src={image03} alt="" />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <a href="">
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box-gallery">
                <img id="gal-backImg" src={image04} alt="" />
                <div className="box-content">
                  <h3 className="title">Ver Imagen</h3>
                  <ul className="icon">
                    <li>
                      <a href="">
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box-gallery">
                <img id="gal-backImg" src={image04} alt="" />
                <div className="box-content">
                  <h3 className="title">Ver Imagen</h3>
                  <ul className="icon">
                    <li>
                      <a href="">
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box-gallery">
                <img id="gal-backImg" src={image05} alt="" />
                <div className="box-content">
                  <h3 className="title">Ver Imagen</h3>
                  <ul className="icon">
                    <li>
                      <a href="">
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box-gallery">
                <img id="gal-backImg" src={image06} alt="" />
                <div className="box-content">
                  <h3 className="title">Ver Imagen</h3>
                  <ul className="icon">
                    <li>
                      <a href={image06}>
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box-gallery">
                <img id="gal-backImg" src={image07} alt="" />
                <div className="box-content">
                  <h3 className="title">Ver Imagen</h3>
                  <ul className="icon">
                    <li>
                      <a href={image07}>
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
