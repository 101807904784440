import './assets/styles/header.css';
import './assets/styles/service.css';
import './assets/styles/card.css';
import './assets/styles/testimonial.css';
import './assets/styles/perfil.css';
import './assets/styles/footer.css';

import { Main } from './pages/Main';
import { FisioterapiaPage } from './pages/FisioterapiaPage';
import { CefisePage } from './pages/CefisePage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { TrayectoriaPage } from './pages/TrayectoriaPage';
import { ContactanosPage } from './pages/ContactanosPage';
import { EnfermedadesPage } from './pages/EnfermedadesPage';
import { PreQuirurgica } from './components/Enfermedades/PreQuirurgica';
import {
  CaderaProtesis,
  Carpiano,
  Ciatica,
  Codo,
  CodoGolfista,
  Congelado,
  Electroterapia,
  Espalda,
  EspaldaHernia,
  FracturaTobillo,
  LigamentoRodilla,
  Mano,
  PostQuirurgica,
  ReemplazoRodilla,
  Tendinitis,
  TerapiaDeportiva,
  TerapiaQuemaduras,
  Tobillo,
} from './components/Enfermedades';
import { Menu } from './components/Menu';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/fisioterapia" element={<FisioterapiaPage />} />
          <Route path="/cefise" element={<CefisePage />} />
          <Route path="/trayectoria" element={<TrayectoriaPage />} />
          <Route path="/contactanos" element={<ContactanosPage />} />
          <Route path="/enfermedad" element={<EnfermedadesPage />} />
          <Route path="/menu" element={<Menu />} />
        </Routes>

        <Routes>
          <Route path="/prequirurgica" element={<PreQuirurgica />} />
          <Route path="/postquirurgica" element={<PostQuirurgica />} />
          <Route path="/caderaprotesis" element={<CaderaProtesis />} />
          <Route path="/carpiano" element={<Carpiano />} />
          <Route path="/ciatica" element={<Ciatica />} />
          <Route path="/codo" element={<Codo />} />
          <Route path="/codogolfista" element={<CodoGolfista />} />
          <Route path="/congelado" element={<Congelado />} />
          <Route path="/electroterapia" element={<Electroterapia />} />
          <Route path="/espalda" element={<Espalda />} />
          <Route path="/espaldahernia" element={<EspaldaHernia />} />
          <Route path="/fracturatobillo" element={<FracturaTobillo />} />
          <Route path="/ligamentorodilla" element={<LigamentoRodilla />} />
          <Route path="/mano" element={<Mano />} />
          <Route path="/reemplazorodilla" element={<ReemplazoRodilla />} />
          <Route path="/tendinitis" element={<Tendinitis />} />
          <Route path="/terapiadeportiva" element={<TerapiaDeportiva />} />
          <Route path="/terapiaquemaduras" element={<TerapiaQuemaduras />} />
          <Route path="/tobillo" element={<Tobillo />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
