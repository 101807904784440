import '../assets/styles/cefise-css/cefise.css';
import gimnasio from '../assets/img/fotografias/gimnasio.jpg';
import recepcion from '../assets/img/fotografias/recepcion.jpg';
import gimnasio2 from '../assets/img/fotografias/gimnasio2.jpg';
import recepcion2 from '../assets/img/fotografias/recepcion2.jpg';
import cuartos from '../assets/img/fotografias/cuartos.jpg';
import revision from '../assets/img/fotografias/revision.jpg';
import revision2 from '../assets/img/fotografias/revision2.jpg';
import pasillo2 from '../assets/img/fotografias/pasillo2.jpg';
import oficina from '../assets/img/fotografias/oficina.jpg';

import { Fade, Rotate, Zoom } from 'react-reveal';
import { useState } from 'react';

export const Cefise = () => {
  const [animation, setAnimation] = useState(0);

  window.onscroll = () => {
    let y = window.scrollY;
    setAnimation(y);
    console.log(y);
  };

  return (
    <>
      <div className="container-cefise">
        <div className="linea"></div>
        <Fade bottom>
          <div className="container--one">
            <h1>¿Quienes Somos?</h1>

            <div className="img-cefise">
              <Zoom left>
                <img src={gimnasio} />
              </Zoom>
              <Zoom left delay={700}>
                <img src={recepcion} />
              </Zoom>
              <Zoom left delay={900}>
                <img src={gimnasio2} />
              </Zoom>
            </div>

            {/* <p>
              Somos un Centro Fisioterapéutico Especializado en rehabilitación,
              establecido en la ciudad de San Pedro Sula, Honduras, desde el año
              1995.
            </p> */}
            <p>
              Somos una clínica especializada en rehabilitación fisica. Ayudando
              a nuestros pacientes a maximizar su calidad de vida,
              reincorporandolos a sus actividades diaras en el menor tiempo
              posible, aplicando en nuestro trabajo las tecnicas mas
              innovadoras; con un equipo altamente capacitado para brindar un
              tratamiento adecuado para cada uno de nuestros pacientes
            </p>
          </div>
        </Fade>
        <div className="linea"></div>

        <Fade left delay={2000}>
          <div className="container--two" onScroll={window.onscroll}>
            <h1>¿Que hacemos?</h1>
            <div className="img-cefise">
              <Zoom left delay={2000}>
                <img src={revision2} />
              </Zoom>
              <Zoom left delay={2700}>
                <img src={pasillo2} />
              </Zoom>
              <Zoom left delay={2900}>
                <img src={oficina} />
              </Zoom>
            </div>
            <p>
              Brindamos una valoración personalizada del paciente con el
              propósito de alcanzar la recuperación y prevención de cualquier
              tipo de lesión y llegar conseguir su bienestar
            </p>
          </div>
        </Fade>
        <div className="linea"></div>

        <Fade right>
          <div className="container--three">
            <h1>Nuestras instalaciones y servicios</h1>
            <p>
              Recuperamos tu bienestar! Nuestras instalaciones constan de
              equipos modernos de electroterapia, laser, ultrasonido, gimnasio
              terapeutico, maquinas de CPM para manejo de hombro y rodilla.
              Somos una clinica con fisioterapeutas altamente calificados,con
              estudios en México, Estados Unidos y Europa con gran experiencia
              profesional y en constante formacion en tecnicas y tratamientos
              para garantizar una recuperacion satisfactoria a nuestros
              pacientes.
            </p>
            <div className="img-cefise">
              <Zoom left>
                <img src={cuartos} />
              </Zoom>
              <Zoom left delay={700}>
                <img src={recepcion2} />
              </Zoom>
              <Zoom left delay={900}>
                <img src={revision} />
              </Zoom>
            </div>
            <div className="img-cefise">
              <Zoom left>
                <img src={gimnasio} />
              </Zoom>
              <Zoom left delay={700}>
                <img src={recepcion} />
              </Zoom>
              <Zoom left delay={900}>
                <img src={gimnasio2} />
              </Zoom>
            </div>
          </div>
        </Fade>
      </div>
    </>
  );
};
