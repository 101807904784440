import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';

export const Card = ({ props, condicion, id, categoria, img }) => {
  return (
    <>
      {props == 'rehabilitación' ? (
        <Fade left>
          <div className="card">
            <img src={img} alt="" id="card-backImg" />
            <div className="servicios">
              <span className="category">Fisioterapia</span>
              <span className="condition">{condicion}</span>
              <span className="service">rehabilitación</span>
            </div>
            <div className="card-linea"></div>
            {/* <div className="card-icon">
              <a href="#">
                <img src={ver} />
              </a>
              <a href="#">
                <img id="producto" src={product} />
              </a>
              <a href="#">
                <img src={anadir} />
              </a>
            </div> */}
            <div className="card-button">
              <Link to={id}>Informacion</Link>
            </div>
          </div>
        </Fade>
      ) : null}

      {props == 'terapia' ? (
        <Fade right>
          <div className="card">
            <img src={img} alt="" id="card-backImg" />
            <div className="servicios">
              <span className="category">Estres</span>
              <span className="condition">{condicion}</span>
              <span className="service">Terapia</span>
            </div>
            <div className="card-linea"></div>
            {/* <div className="card-icon">
              <a href="#">
                <img src={ver} />
              </a>
              <a href="#">
                <img id="producto" src={product} />
              </a>
              <a href="#">
                <img src={anadir} />
              </a>
            </div> */}
            <div className="card-button">
              <a href={id}>Informacion</a>
            </div>
          </div>
        </Fade>
      ) : null}

      {props == 'lesiones' ? (
        <Fade top>
          <div className="card">
            <img src={img} alt="" id="card-backImg" />
            <div className="servicios">
              <span className="category">{categoria}</span>
              <span className="condition">{condicion}</span>
              <span className="service">Lesiones</span>
            </div>
            <div className="card-linea"></div>
            {/* <div className="card-icon">
              <a href="#">
                <img src={ver} />
              </a>
              <a href="#">
                <img id="producto" src={product} />
              </a>
              <a href="#">
                <img src={anadir} />
              </a>
            </div> */}
            <div className="card-button">
              <a href={id}>Informacion</a>
            </div>
          </div>
        </Fade>
      ) : null}

      {/* <div className="card next-button"></div> */}
    </>
  );
};
