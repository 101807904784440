import { Fisioterapia } from '../components/Fisioterapia';
import { Footer } from '../components/Footer';
import { Service } from '../components/Service';
import { Header } from '../components/Header';

export const FisioterapiaPage = () => {
  return (
    <>
      <Header />
      <Fisioterapia />
      <Service />
      <Footer />
    </>
  );
};
