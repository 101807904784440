import location from '../assets/img/location.png';
import phone from '../assets/img/phone.png';
import facebook from '../assets/img/facebook.png';
import instagram from '../assets/img/instagram.png';
import twitter from '../assets/img/twitter.png';
import cefise from '../assets/img/logotipo-cefise.png';
import menuAct from '../assets/img/menu.png';

import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Menu } from './Menu';

export const Header = () => {
  const [menu, setMenu] = useState('-menuactive');

  return (
    <>
      <div className="header-info">
        <div className="header-contact">
          <a
            href="https://www.google.com/maps/place/Col+Zer%C3%B3n,+San+Pedro+Sula+21102/@15.5169469,-88.0372881,15z/data=!4m6!3m5!1s0x8f665b136a369fbb:0xf899cd8d092b33fe!8m2!3d15.5167893!4d-88.0375314!16s%2Fg%2F1tzgkq0y"
            className="header-contact-links"
          >
            <img src={location} className="header-location" />
            Bo. Guamilito. 9 Calle. 9 Ave.
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=50495193600"
            className="header-contact-links"
          >
            <img src={phone} className="header-phone" />
            +504 9519-3600
          </a>
        </div>
        <div className="header-search">
          <input type="text" id="header-search" placeholder="Buscar..." />
        </div>

        <div className="social-media">
          <a href="https://www.facebook.com/cefisehn">
            <img src={facebook} />
          </a>
          <a href="https://www.instagram.com/cefisetherapy/">
            <img src={instagram} />
          </a>
          <a href="#">
            <img src={twitter} />
          </a>
          <a href="/menu" id="menu-desplegable">
            <img src={menuAct} />
          </a>
        </div>
      </div>

      <div className="navbar">
        <div className="logo">
          {/* <h2></h2> */}
          <img src={cefise} className="logo-cefise" />
          <a href="/" className="cefise">
            CEFISE
          </a>
        </div>

        <div className="links">
          <Link to="/cefise" className="text-links">
            ¿Qué es Cefise?
          </Link>
          <Link to="/fisioterapia" className="text-links">
            Fisioterapia
          </Link>
          <Link to="/trayectoria" className="text-links">
            Trayectoria
          </Link>
          <Link to="/contactanos" className="text-links">
            Contáctenos
          </Link>
        </div>
      </div>
    </>
  );
};
