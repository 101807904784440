import hosman from '../assets/img/fotografias/Hosman.jpg';

export const Perfil = () => {
  return (
    <>
      <div id="container">
        <div className="perfil">
          <img src={hosman} className="perfil-cefise" />
        </div>
        <div className="presentacion">
          <h1>En Cefise Somos Expertos en Devolverte Tu Vida</h1>
          <div className="cargo">
            <p id="nombre">Lic. Hosman Fernández</p>
            <p id="cargo">Especialista en Fisioterapia</p>
            <p id="enlace">Visitános en nuestras redes sociales</p>
          </div>
        </div>
      </div>
    </>
  );
};
