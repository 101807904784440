import { useEffect, useState } from 'react';

export const useActive = () => {
  const [active, setActive] = useState('active');
  const [state, setstate] = useState('noActive');
  const [tercer, setTercer] = useState('noActive');
  const [cuarto, setCuarto] = useState('noActive');
  const [quinto, setQuinto] = useState('noActive');
  const [sexto, setSexto] = useState('noActive');
  const [septimo, setSeptimo] = useState('noActive');

  useEffect(() => {
    const isActive = setTimeout(() => {
      setActive('noActive');
    }, 1000);

    const isState = setTimeout(() => {
      setstate('active');
      setTimeout(() => {
        setstate('noActive');
      }, 1000);
    }, 1000);

    const isTercer = setTimeout(() => {
      setTercer('active');
      setTimeout(() => {
        setTercer('noActive');
      }, 1000);
    }, 3000);

    const isCuarto = setTimeout(() => {
      setCuarto('active');
      setTimeout(() => {
        setCuarto('noActive');
      }, 1000);
    }, 4000);

    const isQuinto = setTimeout(() => {
      setQuinto('active');
      setTimeout(() => {
        setQuinto('noActive');
      }, 1000);
    }, 5000);

    const isSexto = setTimeout(() => {
      setSexto('active');
      setTimeout(() => {
        setSexto('noActive');
      }, 1000);
    }, 6000);

    const isSeptimo = setTimeout(() => {
      setSeptimo('active');
      setTimeout(() => {
        setSeptimo('noActive');
        setActive('active');
      }, 1000);
    }, 7000);

    return () => {
      clearTimeout(isActive);
      clearTimeout(isState);
      clearTimeout(isTercer);
      clearTimeout(isCuarto);
      clearTimeout(isQuinto);
      clearTimeout(isSexto);
      clearTimeout(isSeptimo);
    };
  }, [active]);

  return {
    active,
    state,
    tercer,
    cuarto,
    quinto,
    sexto,
    septimo,
  };
};
